import React from 'react'
import './JumpingButton.scss'

const JumpingButton = () => {
    return (
        <div className="jumping-button-container">
            <div class="jumping-button-wrap">
                <div class="jumping-button">
                    <h3>Get Your Free Website Audit</h3>
                </div>
                <div class="shadow"></div>
            </div>
        </div>
    )
}

export default JumpingButton